/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import BUSCPA5NEWSLETTERJune20241 from '/static/newsletters/BUSCP-A5-NEWSLETTER-June-2024-1.pdf';
import BUSCPA5NEWSLETTERNovember23 from '/static/newsletters/BUSCP-A5-NEWSLETTER-November-23.pdf';
import BUSCPA5NEWSLETTERAugust23 from '/static/newsletters/BUSCP-A5-NEWSLETTER-August-23.pdf';
import BUSCPA5NEWSLETTERFebruary20232 from '/static/newsletters/BUSCP-A5-NEWSLETTER-February-2023.2.pdf';
import BUSCPA5NEWSLETTERNovember2022 from '/static/newsletters/BUSCP-A5-NEWSLETTER-November-2022.pdf';
import BUSCPA5NEWSLETTERJuly2022 from '/static/newsletters/BUSCP_A5_NEWSLETTER_July_2022.pdf';
import BUSCPA5NEWSLETTERApril2022 from '/static/newsletters/BUSCP-A5-NEWSLETTER-April-2022.pdf';
import BUSCPA5NEWSLETTERNovember2021 from '/static/newsletters/BUSCP-A5-NEWSLETTER-November-2021.pdf';
import BUSCPA5NEWSLETTERAugust2021 from '/static/newsletters/BUSCP-A5-NEWSLETTER-August-2021.pdf';
import BUSCPA5NEWSLETTERJune2021 from '/static/newsletters/BUSCP-A5-NEWSLETTER-June-2021.pdf';
import BUSCPA5NEWSLETTERFebruary20211 from '/static/newsletters/BUSCP-A5-NEWSLETTER-February-2021-1.pdf';
import BUSCPA5NEWSLETTEROctober2020081020 from '/static/newsletters/BUSCP-A5-NEWSLETTER-October-2020-081020.pdf';
import BUSCPA5NEWSLETTER3April2020 from '/static/newsletters/BUSCP-A5-NEWSLETTER-3-April-2020.pdf';
import Jan2020v4 from '/static/newsletters/Jan-2020-v4.pdf';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Newsletters"), "\n", React.createElement(_components.p, null, "The latest Parish Council newsletters can be found below"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERJune20241,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : June 2024 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERNovember23,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : November 2023 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERAugust23,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : August 2023 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERFebruary20232,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : February 2023 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERNovember2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : November 2022 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERJuly2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : July 2022 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERApril2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : April 2022 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERNovember2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : November 2021 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERAugust2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : August 2021 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERJune2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : June 2021 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTERFebruary20211,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : February 2021 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTEROctober2020081020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : October 2020 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: BUSCPA5NEWSLETTER3April2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : April 2020 Newsletter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Jan2020v4,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : January 2020 Newsletter"), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
